import React from 'react'
import PropTypes from 'prop-types'
import {
    contractPropType,
    paymentMethodPropType,
    subscriptionPropType
} from 'proptypes'
import { useDealership } from 'DealershipContext'
import { formatMoney } from 'services/fmt'
import { errorKind } from 'services/api'
import LabeledInput from 'components/LabeledInput'
import DatePicker from 'components/DatePicker'
import Form from 'components/Form'
import Overlay from 'components/Overlay'
import ReauthenticateForm from 'components/ReauthenticateForm'
import useApi from 'components/UseApi'
import Heading from 'components/Heading'
import ConfirmPayment from './components/ConfirmPayment'
import Spinner from 'components/Spinner'
import styles from './PaymentForm.module.css'
import Link from 'components/Link'

// Returns the amount to which a payment was restricted (min or max depending on
// the restriction in question). Guarantess the amount is >= 0.
const paymentRestrictionAmount = (restriction, contract) => {
    let amount
    switch (restriction.kind) {
        case 'flat':
            amount = restriction.flat_amount
            break
        case 'regular':
            amount = contract.regular_payment_amount
            break
        case 'due':
            amount = contract.due_amount
            break
        default:
            amount = 0
    }

    if (!amount || amount < 0) {
        return 0
    }
    return amount
}

/**
 * Form used to make a payment on a vehicle.
 */
const PaymentForm = ({
    contract,
    paymentMethods,
    subscription,
    onCancel,
    onSuccess
}) => {
    const { dealership } = useDealership()

    const [amount, setAmount] = React.useState('')
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
        paymentMethods.length === 1 ? paymentMethods[0] : {}
    )
    const [reauthenticate, setReauthenticate] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [shouldRefresh, setShouldRefresh] = React.useState(false)
    const [confirm, setConfirm] = React.useState(false)
    const [agreeTerms, setAgreeTerms] = React.useState(false)

    // Payment date, determines which API call to use
    // Today's date will process payment as normal
    // Date in the future will create a scheduled payment
    const [paymentDate, setPaymentDate] = React.useState(new Date())
    // Comparison date, and used in date picker and date check
    const [todaysDate] = React.useState(new Date())

    // Handler for when the paymentDate is changed
    const setPaymentDateHandler = (newDateValue) => {
        // newDateValue.setHours(0, 0, 0, 0)
        // Ensure the date is not in the past
        if (
            newDateValue.setHours(0, 0, 0, 0) < todaysDate.setHours(0, 0, 0, 0)
        ) {
            // In the past, ignore change
            return
        }
        // Update the value
        setPaymentDate(newDateValue)
    }

    const [payment, doPayment] = useApi()

    const totalAmount =
        (Number.parseFloat(amount) || 0) +
        (Number.parseFloat(dealership.payment_fee) || 0)

    // Normal config to process a payment immediately
    // Used when paymentDate == today
    const doPaymentCfg = {
        method: 'post',
        endpoint: '/payments',
        body: {
            contract_id: contract.id,
            contract_modified: contract.modified,
            payment_method_id: selectedPaymentMethod.id,
            amount: Number.parseFloat(amount),
            fee: Number.parseFloat(dealership.payment_fee)
        }
    }
    // Alternate config to schedule a payment to process for a date in the future
    // Used when paymentDate > today
    const doScheduledPaymentCfg = {
        method: 'post',
        endpoint: '/transactions',
        body: {
            contract_id: contract.id,
            contract_modified: contract.modified,
            payment_method_id: selectedPaymentMethod.id,
            amount: Number.parseFloat(amount),
            fee: Number.parseFloat(dealership.payment_fee),
            // Convert to Javascript date to UNIX Timestamp
            scheduled_payment_date: Math.floor(paymentDate.getTime() / 1000)
        }
    }

    // Hook to handle api response.
    React.useEffect(() => {
        if (payment.loading) {
            return
        }

        if (payment.error) {
            switch (payment.error.kind) {
                case errorKind.REAUTHENTICATION_REQUIRED:
                    setReauthenticate(true)
                    break
                case errorKind.BAD_REQUEST:
                    setShouldRefresh(true)
                    setErrorMessage(payment.error.message)
                    break
                default:
                    setErrorMessage(payment.error.message)
            }

            return
        }

        if (payment.data) {
            onSuccess(payment.data) // Expected to unmount the form.
        }
    }, [payment, onSuccess, dealership, doPayment, amount, contract])

    if (errorMessage) {
        return (
            <>
                <Heading size="md">Payment Unsuccessful</Heading>
                <p>{errorMessage}</p>
                <button type="button" onClick={() => onCancel(shouldRefresh)}>
                    OK
                </button>
            </>
        )
    }

    // Handle loading state for the doPayment API call
    if (payment.loading) {
        return <Spinner />
    }

    const minAmount = paymentRestrictionAmount(dealership.min_payment, contract)
    const maxAmount = paymentRestrictionAmount(dealership.max_payment, contract)
    let restrictions = []
    if (minAmount) {
        restrictions.push(`min ${formatMoney(minAmount)}`)
    }
    if (maxAmount) {
        restrictions.push(`max ${formatMoney(maxAmount)}`)
    }
    const amountInputLabel =
        restrictions.length > 0 ? (
            <div className={styles.paymentAmountLabel}>
                <span className={styles.paymentAmountLabelText}>
                    Payment Amount
                </span>
                <wbr />
                <span className={styles.paymentAmountLabelRestriction}>
                    {restrictions.join(' ')}
                </span>
            </div>
        ) : (
            <div className={styles.paymentAmountLabel}>
                <span className={styles.paymentAmountLabelText}>
                    Payment Amount
                </span>
            </div>
        )

    const handleSubmit = (confirmed = false) => {
        // Ensure amount only contains digits and up to one decimal point after commas and leading/trailing spaces are stripped.
        if (
            !amount
                .trim()
                .replace(/,/g, '')
                .match(/^\$?\d*\.?\d*$/)
        ) {
            setErrorMessage(
                'The payment amount entered contains one or more invalid characters.'
            )
            return
        }

        // If confirmed is false, we need to get confirmation of the input.
        if (!confirmed) {
            setConfirm(true)
            return
        }

        // Determine if we're processing payment now, or scheduling in the future
        let cfg = doPaymentCfg
        if (paymentDate > todaysDate) {
            // Payment is in the future, we'll use scheduled payment config
            cfg = doScheduledPaymentCfg
        }
        // Make the API call
        doPayment(cfg)
    }

    return (
        <>
            <Form
                disabled={payment.loading || !!payment.data}
                onSubmit={() => {
                    handleSubmit()
                }}
                onCancel={() => onCancel(shouldRefresh)}
                coloredButtons={true}
            >
                <LabeledInput
                    label={amountInputLabel}
                    type="number"
                    step="0.01"
                    min={minAmount > 0 ? minAmount : 0.01}
                    max={maxAmount > 0 ? maxAmount : 999999.99}
                    required
                    value={amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                    }}
                    disabled={payment.loading || !!payment.data}
                    className={styles.amountInput}
                />
                {dealership.payment_fee !== 0 && (
                    <>
                        <LabeledInput
                            label="Online Payment Fee"
                            type="number"
                            step="0.01"
                            value={dealership.payment_fee}
                            disabled
                            className={styles.amountInput}
                        />
                        <LabeledInput
                            label="Total Payment Amount"
                            type="number"
                            step="0.01"
                            value={totalAmount}
                            disabled
                            className={styles.amountInput}
                        />
                    </>
                )}
                <div>
                    <div style={{ width: '45%', float: 'left' }}>
                        <DatePicker
                            label="Payment Date"
                            type="datepicker"
                            shouldCloseOnSelect={true}
                            selected={paymentDate}
                            onChange={(date) => setPaymentDateHandler(date)}
                            minDate={todaysDate}
                        />
                    </div>
                    <div style={{ padding: '24px 0px 0px 0px' }}>
                        <Link to={'/subscription/' + contract.id} button={true}>
                            {subscription ? 'VIEW AUTO PAY' : 'SET UP AUTO PAY'}
                        </Link>
                    </div>
                </div>

                <fieldset disabled={payment.loading || !!payment.data}>
                    <legend>Payment Method</legend>
                    {paymentMethods.map((pm) => {
                        return (
                            <LabeledInput
                                label={
                                    pm.nickname || `${pm.provider} ${pm.last_4}`
                                }
                                type="radio"
                                name="payment-method"
                                required
                                checked={selectedPaymentMethod.id === pm.id}
                                onChange={() => {
                                    setSelectedPaymentMethod(pm)
                                }}
                                key={`payment-method-${pm.id}`}
                            />
                        )
                    })}
                </fieldset>
                <LabeledInput
                    className={styles.terms}
                    label={
                        <>
                            I agree to the PayMyCar{' '}
                            <Link to="/legal" target="_blank">
                                terms and conditions
                            </Link>
                            .
                        </>
                    }
                    type="checkbox"
                    required
                    checked={agreeTerms}
                    onChange={(e) => {
                        setAgreeTerms(e.target.checked)
                    }}
                />
            </Form>
            {confirm && (
                <Overlay>
                    <ConfirmPayment
                        contract={contract}
                        amount={totalAmount}
                        method={selectedPaymentMethod}
                        paymentDate={paymentDate}
                        onConfirm={() => {
                            setConfirm(false)
                            handleSubmit(true)
                        }}
                        onCancel={() => {
                            setConfirm(false)
                        }}
                    />
                </Overlay>
            )}
            {reauthenticate && (
                <Overlay>
                    <ReauthenticateForm
                        onSuccess={() => {
                            setReauthenticate(false)
                            handleSubmit(true)
                        }}
                        onCancel={() => {
                            onCancel(shouldRefresh)
                        }}
                    />
                </Overlay>
            )}
        </>
    )
}

PaymentForm.propTypes = {
    contract: contractPropType.isRequired,
    paymentMethods: PropTypes.arrayOf(paymentMethodPropType).isRequired,
    subscription: subscriptionPropType,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default PaymentForm
