import PropTypes from 'prop-types'
import Form from 'components/Form'
import styles from './ConfirmCancelSubscription.module.css'

const ConfirmCancelSubscription = ({ onConfirm, onCancel }) => {
    return (
        <Form
            onSubmit={onConfirm}
            onCancel={onCancel}
            coloredButtons={true}
            submitButtonText="YES, STOP AUTO PAY"
            cancelButtonText="GO BACK"
            className={styles.form}
        >
            <p>Are you sure you want to stop auto pay?</p>
        </Form>
    )
}

ConfirmCancelSubscription.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default ConfirmCancelSubscription
